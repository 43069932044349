<template>
  <div class="v-stack h-stretch v-stretch full">
    <calendarview
      class="theme-default wrap-item-title-on-hover"
      :show-date="date"
      :startingDayOfWeek="1"
      :displayWeekNumbers="true"
      :items="items"
      currentPeriodLabel="Today"
      locale="cs"
      @click-item="onItemClicked($event)"
    >
      <template #header="{ headerProps }">
        <calendarviewheader :header-props="headerProps" @input="setDate" />
      </template>
    </calendarview>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const moment = require("moment");

export default {
  data() {
    return {
      date: new Date(),
      items: [],
    };
  },
  methods: {
    ...mapActions(["getRecordingDays", "getProjects", "getUsers"]),
    setDate(date) {
      this.date = date;
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    onItemClicked(item) {
      item.originalItem.action();
    },
  },
  mounted() {
    this.getRecordingDays()
      .then((recordingDays) => {
        for (const day of recordingDays) {
          this.items.push({
            id: day._id,
            startDate: day.date,
            title: `${this.formatDate(day.date, "HH:mm")} Shooting - ${
              day.project.client.name
            } / ${day.project.title} / ${day.project.subtitle}`,
            action: () => this.$router.push("/productiondays/" + day._id),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.getProjects()
      .then((projects) => {
        for (const project of projects) {
          this.items.push({
            id: project._id,
            startDate: project.deadline,
            title: `${this.formatDate(project.deadline, "HH:mm")} Deadline - ${
              project.client.name
            } / ${project.title} / ${project.subtitle}`,
            action: () => this.$router.push("/projects/detail/" + project._id),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.getUsers()
      .then((users) => {
        for (const user of users) {
          if (user.birthday) {
            this.items.push({
              id: user._id,
              startDate: moment(user.birthday)
                .year(moment().year())
                .format("YYYY-MM-DD"),
              title: `Birthday ${user.firstName} ${user.lastName}`,
              action: () => this.$router.push("/users/detail/" + user._id),
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.full {
  height: calc(100vh - 139px);
}
</style>

<style lang="scss">
.cv-header {
  button {
    all: unset;
    box-sizing: border-box;
    line-height: 1em;
    font-size: 1em;
    border-width: 1px;
    padding: 0.4em 0.6em;
    border-style: solid;
    border-color: #ddd;
  }
}
</style>